import React, { useContext, useState, useEffect } from 'react'
import * as BPTLStyles from './BPTL.module.scss'
import ImageLeader from '../../ImageLeader/ImageLeader'
import SubLeader from '../../SubLeader/SubLeader'
import Subnav from '../../SubNav/SubNav'
import Hr from '../../Hr/Hr'
import { StaticImage } from 'gatsby-plugin-image'
import { BPTLContext, BPTLStates } from '../../../lib/utils'

const countyLinks = [
  {
    label: 'Bedfordshire',
    url: 'https://herts.muddystilettos.co.uk/herts/best-places-to-live/bedfordshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/beds.jpg`}
        alt={`Bedfordshire`}
      />
    )
  },
  {
    label: 'Berkshire',
    url: 'https://berkshire.muddystilettos.co.uk/berkshire/best-places-to-live/berkshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/berks.jpg`}
        alt={`Berkshire`}
      />
    )
  },
  {
    label: 'Buckinghamshire',
    url: 'https://bucksoxon.muddystilettos.co.uk/bucksoxon/best-places-to-live/buckinghamshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/buckinghamshire.jpg`}
        alt={`Buckinghamshire`}
      />
    )
  },
  {
    label: 'Cambridgeshire',
    url: 'https://suffolkcambs.muddystilettos.co.uk/suffolkcambs/best-places-to-live/cambridgeshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/cambs.jpg`}
        alt={`Cambridgeshire`}
      />
    )
  },
  {
    label: 'Cornwall',
    url: 'https://cornwall.muddystilettos.co.uk/cornwall/best-places-to-live/cornwall',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/cornwall.jpg`}
        alt={`Cornwall`}
      />
    )
  },
  {
    label: 'Derbyshire',
    url: 'https://nottsderbyshire.muddystilettos.co.uk/nottsderbyshire/best-places-to-live/derbyshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/derbyshire.jpg`}
        alt={`Derbyshire`}
      />
    )
  },
  {
    label: 'Devon',
    url: 'https://devon.muddystilettos.co.uk/devon/best-places-to-live/devon',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/devon.jpg`}
        alt={`Devon`}
      />
    )
  },
  {
    label: 'Dorset',
    url: 'https://dorsetsomerset.muddystilettos.co.uk/dorsetsomerset/best-places-to-live/dorset',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/dorset-2024.jpg`}
        alt={`Dorset`}
      />
    )
  },
  {
    label: 'Essex',
    url: 'https://essex.muddystilettos.co.uk/essex/best-places-to-live/essex',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/essex.jpg`}
        alt={`Essex`}
      />
    )
  },
  {
    label: 'Gloucestershire',
    url: 'https://glosworcs.muddystilettos.co.uk/glosworcs/best-places-to-live/gloucestershire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/glos.jpg`}
        alt={`Gloucestershire`}
      />
    )
  },
  {
    label: 'Hampshire & IOW',
    url: 'https://hants.muddystilettos.co.uk/hants/best-places-to-live/hampshire-iow',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/hants.jpg`}
        alt={`Hampshire`}
      />
    )
  },
  {
    label: 'Hertfordshire',
    url: 'https://herts.muddystilettos.co.uk/herts/best-places-to-live/hertfordshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/herts.jpg`}
        alt={`Hertfordshire`}
      />
    )
  },
  {
    label: 'Kent',
    url: 'https://kent.muddystilettos.co.uk/kent/best-places-to-live/kent',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/kent.jpg`}
        alt={`Kent`}
      />
    )
  },
  {
    label: 'Leicestershire & Rutland',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk/northantsleicsrutland/best-places-to-live/leicestershire-rutland/',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/rutland.jpg`}
        alt={`Leicestershire`}
      />
    )
  },
  {
    label: 'Norfolk',
    url: 'https://norfolk.muddystilettos.co.uk/norfolk/best-places-to-live/norfolk',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/norfolk-home.jpg`}
        alt={`Norfolk`}
      />
    )
  },
  {
    label: 'Nottinghamshire',
    url: 'https://nottsderbyshire.muddystilettos.co.uk/nottsderbyshire/best-places-to-live/nottinghamshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/notts.jpg`}
        alt={`Nottinghamshire`}
      />
    )
  },
  {
    label: 'Northamptonshire',
    url: 'https://northantsleicsrutland.muddystilettos.co.uk/northantsleicsrutland/best-places-to-live/northamptonshire/',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/northants-2024.jpg`}
        alt={`Northamptonshire`}
      />
    )
  },
  {
    label: 'Oxfordshire',
    url: 'https://bucksoxon.muddystilettos.co.uk/bucksoxon/best-places-to-live/oxfordshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/ox.jpg`}
        alt={`Oxfordshire`}
      />
    )
  },
  {
    label: 'Somerset & Bristol',
    url: 'https://dorsetsomerset.muddystilettos.co.uk/dorsetsomerset/best-places-to-live/somerset',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/somerset-2024.jpg`}
        alt={`Somerset & Bristol`}
      />
    )
  },
  {
    label: 'Suffolk',
    url: 'https://suffolkcambs.muddystilettos.co.uk/suffolkcambs/best-places-to-live/suffolk',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/suffolk.jpg`}
        alt={`Suffolk`}
      />
    )
  },
  {
    label: 'Surrey',
    url: 'https://surrey.muddystilettos.co.uk/surrey/best-places-to-live/surrey',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/surrey-2024.jpg`}
        alt={`Surrey`}
      />
    )
  },
  {
    label: 'Sussex',
    url: 'https://sussex.muddystilettos.co.uk/sussex/best-places-to-live/sussex',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/sussex.jpg`}
        alt={`Sussex`}
      />
    )
  },
  {
    label: 'Warwickshire',
    url: 'https://warks.muddystilettos.co.uk/warks/best-places-to-live/warwickshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/warks.jpg`}
        alt={`Warwickshire`}
      />
    )
  },
  {
    label: 'West Midlands',
    url: 'https://warks.muddystilettos.co.uk/warks/best-places-to-live/west-midlands',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/west-midlands.jpg`}
        alt={`West Midlands`}
      />
    )
  },
  {
    label: 'Wiltshire',
    url: 'https://wilts.muddystilettos.co.uk/wilts/best-places-to-live/wiltshire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/wilts.jpg`}
        alt={`Wiltshire`}
      />
    )
  },
  {
    label: 'Worcestershire',
    url: 'https://glosworcs.muddystilettos.co.uk/glosworcs/best-places-to-live/worcestershire',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/worcs.jpg`}
        alt={`Worcestershire`}
      />
    )
  },
  {
    label: 'Best of the Rest',
    url: 'https://muddystilettos.co.uk/best-places-to-live/best-of-the-rest/',
    imgLabel: (
      <StaticImage
        src={`../../../../static/images/best-places-to-live/best-of-the-rest.jpg`}
        alt={`Best of the Rest`}
      />
    )
  }
]

const BPTL = () => {
  const [view, setView] = useState<string>('grid')
  const bptlState = useContext(BPTLContext)
  const [readersVoteIsBack, setReadersVoteIsBack] = useState<
    JSX.Element | undefined
  >(undefined)
  const [heroText, setHeroText] = useState<JSX.Element | undefined>(undefined)
  const [heroImgSrc, setHeroImgSrc] = useState<string>(
    'https://www.shutterstock.com/shutterstock/photos/746454388/display_1500/stock-photo-a-small-and-cozy-harbor-in-the-town-of-exmouth-there-are-many-vessels-moored-here-around-the-746454388.jpg'
  )

  /* Blocks to display at double width */
  const doubleWidth = [3, 6, 10]

  console.log('BPTL state:', bptlState)

  useEffect(() => {
    console.log('useEffect', bptlState)
    if (
      bptlState === BPTLStates.REGIONAL ||
      bptlState === BPTLStates.NATIONAL
    ) {
      setReadersVoteIsBack(
        <p className={BPTLStyles.VoteLead}>
          <strong>READERS VOTE IS BACK!</strong>
        </p>
      )
    }

    if (bptlState === BPTLStates.REGIONAL) {
      setHeroText(
        <>
          <p>Now it’s time to have your say!</p>
          <p>
            VOTE NOW to show your local area some love from Muddy’s curated list
            of top 260 places to live in 2024. Voting closes 15 August and
            regional winners announced on 5 September.
          </p>
          <p>Click on your favourite county below and cast your vote!</p>
        </>
      )
    } else if (bptlState === BPTLStates.REGIONAL_CLOSED) {
      setHeroText(
        <p>
          <strong>REGIONAL VOTING IS CLOSED!</strong> Winners will be announced
          on 5 Sept, so watch this space to see where you&apos;ve voted as the
          hottest place to live in your county.
        </p>
      )
    } else if (bptlState === BPTLStates.NATIONAL) {
      setReadersVoteIsBack(
        <p className={BPTLStyles.VoteLead}>
          <strong>READERS VOTE!</strong>
        </p>
      )
      setHeroText(
        <div>
          <p>
            Muddy&apos;s curated the Top 250 best places to live in 2023 and{' '}
            <strong>THE RESULTS ARE IN!</strong>{' '}
          </p>
          <p>
            Click on your county to find out where you voted as the hottest
            place to live near you.
          </p>
          <p>
            2023 National winner to be announced March, 28th - watch this space!
          </p>
        </div>
      )
    } else if (bptlState === BPTLStates.NATIONAL_CLOSED) {
      setReadersVoteIsBack(
        <p className={BPTLStyles.VoteLead}>
          <strong>READERS VOTE!</strong>
        </p>
      )
      setHeroImgSrc('./images/Exmouth.png')
      setHeroText(
        <div>
          <p>
            Muddy&apos;s curated the Top 250 best places to live in 2023 and{' '}
            <strong>THE RESULTS ARE IN!</strong>
          </p>
          <p>
            2023 National winner is <strong>EXMOUTH!</strong>
          </p>
          <p>
            Click on your county to find out where you voted as the hottest
            place to live near you.
          </p>
        </div>
      )
    }
  }, [bptlState])

  return (
    <div className={BPTLStyles.Wrapper}>
      <ImageLeader short>
        <>
          <StaticImage
            src={
              '../../../../static/images/best-places-to-live/bptl-header.jpg'
            }
            alt={`Best Places to Live`}
          />
          <div className={BPTLStyles.Shader}></div>
          <div className={BPTLStyles.ReaderVoteOverlay}>
            {readersVoteIsBack && readersVoteIsBack}
            <h1>
              Best Places to Live
              <span />
              2024
            </h1>
            {heroText}
          </div>
        </>
      </ImageLeader>
      <SubLeader>
        <div className={BPTLStyles.SponsoredBy}>
          <p>In association with</p>
          <div className={BPTLStyles.SponsorsLogo}>
            <StaticImage
              src={`../../../../static/images/best-places-to-live/kf-brandmark-white.png`}
              alt={`Knight Frank`}
            />
          </div>
        </div>
        <p>
          Planning a move? Discover the Top 260 Places to Live in 2024 with our
          insider knowledge of England&apos;s loveliest villages, towns and
          cities
        </p>
      </SubLeader>
      <div className={BPTLStyles.InnerWrapper}>
        <div className={BPTLStyles.Spacer}>
          <Subnav alignCenter>
            <ul>
              <li
                onClick={() => setView('grid')}
                data-id={`${view === 'grid' ? 'isActive' : ''}`}
              >
                Grid view
              </li>
              <li
                onClick={() => setView('list')}
                data-id={`${view === 'list' ? 'isActive' : ''}`}
              >
                List view
              </li>
            </ul>
          </Subnav>
          <Hr />
        </div>
        {view === 'grid' && (
          <div className={BPTLStyles.Counties}>
            {countyLinks.map(({ label, url, imgLabel }, index) => (
              <div
                key={index}
                className={`${BPTLStyles.County} ${
                  doubleWidth.includes(index) ? BPTLStyles.DoubleWidth : ''
                }`}
              >
                <a href={url}>
                  {imgLabel && imgLabel}
                  <div className={BPTLStyles.Shader}></div>
                  <span className={BPTLStyles.Title}>{label}</span>
                </a>
              </div>
            ))}
          </div>
        )}
        {view === 'list' && (
          <div className={BPTLStyles.CountyList}>
            <ul>
              {countyLinks.map(({ label, url }, index) => (
                <li key={index}>
                  <a href={url}>{label}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default BPTL
