import * as React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import FooterBanner from '../components/Banners/FooterBanner/FooterBanner'
import Footer from '../components/Footer/Footer'
import BPTL from '../components/layoutComponents/bptl/BPTL'
import PageMeta from '../components/PageMeta/PageMeta'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'
import { type IBestPlacesToLiveQueryData } from '../graphql/queries/BestPlacestoLiveQuery'

export const query = graphql`
  query BestPlacesToLive {
    wpgraphql {
      allReaderTreats(first: 4) {
        nodes {
          link
          title
          id
          databaseId
          uri
          featuredImage {
            node {
              mediaItemUrl
              altText
              homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
            }
          }
        }
      }
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
    }
  }
`

interface BestPlacesToLiveProps {
  data: IBestPlacesToLiveQueryData
}

const BestPlacesToLive = ({
  data: {
    wpgraphql: { adStarCodes, generalSettings }
  }
}: BestPlacesToLiveProps) => {
  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Best Places to Live | Muddy Stilettos',
          image:
            'https://muddystilettos.co.uk/images/best-places-to-live/Leader.jpg'
        }}
      />
      <Header
        siteId={generalSettings.siteId}
        siteName={generalSettings.title}
      />
      <TopBanner ads={adStarCodes} pageType={`bptl`} showFooterLB />
      <Leaderboard adSlot={'TopLB'} sticky />
      <BPTL />
      <FooterBanner />
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default BestPlacesToLive
